.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.wbt {
  width: 100px;
  margin-right: 10px;
}
.reactEasyCrop_Container{
  z-index: 9;
}
.appBar {
  position: relative,
}
.title {
  margin-left: spacing(2);
  flex: 1;
}
.app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.react-dual-listbox{
  height: 500px !important;
}


.container {
  width: 100%;
}
.progressbar {
  counter-reset: step;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 18%;
  position: relative;
  text-align: center;
  cursor: pointer;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  line-height : 30px;
  border: 1px solid #ddd;
  border-radius: 100%;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  background-color: #fff;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 15px;
  left: -50%;
  z-index : -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: green;
  background-color: green;
    color: white;
} 
.progressbar li.active + li:after {
  background-color: green;
}

.tarifs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* max-width: 1200px; */
  margin: 0 auto;
}
.tarifs .box {
  width: 100%;
  text-align: center;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
}
.tarifs .box h4 {
  font-size: 20px;
  font-weight: 400;
  padding: 0 20px;
  margin-bottom: 0;
}
.tarifs .box p {
  font-size: 14px;
}

.tarifs .box span {
  font-size: 40px;
}

.tarifs .radio-card label {
  padding: 0;
  transition: all 0.3s ease-in-out;
  padding: 30px;

}
.tarifs .radio-card label:hover {
  cursor: pointer;
  background: tint(#00cc99, 85);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);

}
.tarifs .radio-card label:active {
  background: shade(#00cc99, 5);
  color: tint(#00cc99, 80);

}
.tarifs .radio-card input[type="radio"]:checked ~ label {
  background: url('https://cdn0.iconfinder.com/data/icons/social-messaging-ui-color-shapes-3/3/31-512.png') #03a9f4;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 5px 5px;
  color: white;
}
.tarifs .planes-radio {
  display: none;
}
.tab-content{
  display: none;
}
.tarifs .small-text {
  text-align: center;
  width: 100%;
}
.tarifs .bottom {
  display: flex;
  flex: 1;
}
.tarifs .bottom .takemymoney {
  flex-basis: 50%;
}
.tarifs .bottom .next-step {
  flex-basis: 50%;
  display: flex;
  justify-content: flex-end;
}